<template>
    <div data-testid="event-wizard-step-date">
        <main>
            <h1>Do you have a date in mind?</h1>
            <div class="content relative">
                <date-picker
                    v-model="selectedDate"
                    :autoApply="true"
                    :enableTimePicker="false"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :month-change-on-scroll="false"
                    :teleport="true"
                    :transitions="false"
                    placeholder="Select a date"
                    data-testid="event-wizard-date-picker"
                    week-start="0"
                >
                    <template #input-icon>
                        <calendar-icon
                            class="ml-3 h-5 w-5 stroke-1 text-black"
                        ></calendar-icon>
                    </template>
                </date-picker>
                <p class="mt-2 text-sm text-gray-500">
                    Leave this blank if you're flexible on a date.
                </p>
            </div>
        </main>
        <modal-footer @next-click="proceed"></modal-footer>
    </div>
</template>
<script setup>
import { useEventDateFilter } from '@/composables/useEventDateFilter';
import { useEventWizard } from '@/composables/useEventWizard';
import { CalendarIcon } from '@heroicons/vue/outline';
import DatePicker from '@vuepic/vue-datepicker';
import { DateTime } from 'luxon';
import ModalFooter from '../ModalFooter.vue';

const { nextStep, pushFilter, getFilter } = useEventWizard();
const { minDate, maxDate, selectedDate, dateFormat } = useEventDateFilter(
    getFilter('event_date')
);

const proceed = () => {
    const formatted = selectedDate.value
        ? DateTime.fromJSDate(selectedDate.value).toFormat(dateFormat)
        : null;
    pushFilter('event_date', formatted);
    nextStep();
};
</script>
