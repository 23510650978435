<template>
    <div>
        <grid-header :total-results="totalResults" title="Search Results">
            <template v-slot:results.copy
                ><span>
                    {{ totalResults }}
                    {{ totalResults > 1 ? 'results' : 'result' }} based on your
                    search</span
                ></template
            >
        </grid-header>

        <placeholder-grid
            v-if="searching"
            class="tiles-grid"
        ></placeholder-grid>

        <div v-else>
            <product-grid
                class="tiles-grid"
                data-testid="search-results-grid"
                :products="products"
                :currency="productListMeta.currency"
            ></product-grid>
            <simple-pagination
                class="mt-8 flex justify-center at1024:mt-12"
                :current-page="currentPage"
                :pages="pageCount"
                @page-next-requested="loadResults(currentPage + 1)"
                @page-prev-requested="loadResults(currentPage - 1)"
            ></simple-pagination>
        </div>
    </div>
</template>

<script setup>
import { deconstructUrlParams } from '@/composables/usePushHistoryState';
import { useSearch } from '@/composables/useSearch';
import { onBeforeMount, watch } from 'vue';
import VueScrollTo from 'vue-scrollto';
import GridHeader from './ProductList/GridHeader';
import PlaceholderGrid from './ProductList/PlaceholderGrid';
import ProductGrid from './ProductList/ProductGrid';
import SimplePagination from './SimplePagination';

const props = defineProps({
    searchQuery: {
        type: String,
        required: true,
    },
    searchType: {
        type: String,
        required: false,
        default: 'virtual',
    },
});

const {
    searching,
    products,
    totalResults,
    currentPage,
    pageCount,
    productListMeta,
    loadResults,
} = useSearch(props.searchQuery, props.searchType);

onBeforeMount(() => {
    initialLoad();
    window.onpopstate = (e) => {
        initialLoad();
    };
});

watch(products, (newProducts) => {
    if (newProducts.length > 0) {
        VueScrollTo.scrollTo('body', 650, {
            force: false,
        });
    }
});

const initialLoad = () => {
    let params = deconstructUrlParams([], [], ['page']);
    loadResults(params.page || 1, false);
};
</script>
