import { useCollectionFilters } from '@/composables/useCollectionFilters';
import { ref } from 'vue';

const { filterOptions } = useCollectionFilters();

export function useBudgetFilter(initialGroupSize, initialBudget) {
    const minPricePerPerson = filterOptions.minPricePerPerson;
    const maxPricePerPerson = filterOptions.maxPricePerPerson;
    if (typeof initialGroupSize === 'undefined') {
        initialGroupSize = 20;
    }
    if (!initialBudget) {
        initialBudget = [minPricePerPerson, maxPricePerPerson];
    }
    const groupSize = ref(initialGroupSize);
    const budget = ref(initialBudget);

    const validateGroupSizeInput = (defaultGroupSize = 20) => {
        if (isNaN(parseInt(groupSize.value)) || groupSize.value < 1) {
            groupSize.value = defaultGroupSize;
        }
    };

    const validateMinPriceInput = () => {
        if (
            isNaN(parseInt(budget.value[0])) ||
            budget.value[0] < minPricePerPerson
        ) {
            budget.value[0] = minPricePerPerson;
        }
    };

    const validateMaxPriceInput = () => {
        if (
            isNaN(parseInt(budget.value[1])) ||
            budget.value[1] > maxPricePerPerson ||
            budget.value[1] < budget.value[0]
        ) {
            budget.value[1] = maxPricePerPerson;
        }
    };

    return {
        groupSize,
        budget,
        validateGroupSizeInput,
        validateMinPriceInput,
        validateMaxPriceInput,
        minPricePerPerson,
        maxPricePerPerson,
    };
}
