<template>
    <DisclosurePanel v-if="product.isVirtual" id="conferencing-details">
        <ul v-if="product.isSelfHosted">
            <li v-if="product.brickVirtual.isSelfServiceSubscription">
                You will be able to launch this event instantly or schedule it
                for a future date.
            </li>
            <li>
                You will be provided information on how to start the self-hosted
                event after you book. It will be a link or a video.
            </li>
            <li>
                You will need to provide a video conference link (eg. Zoom,
                Google Meet) to your group so they can join you virtually.
            </li>
            <li>
                You can start the event once all of your event participants have
                joined.
            </li>
        </ul>

        <div v-else class="space-y-6">
            <div
                v-if="product.host?.defaultVideoConferencePlatform"
                class="space-y-2"
            >
                <div
                    class="mb-4 flex items-center text-base font-medium at1024:space-x-2"
                >
                    <tv-icon
                        class="hidden h-7 w-7 shrink-0 stroke-1 at1024:inline-block"
                    ></tv-icon>
                    <span
                        >This event is delivered using the video platform</span
                    >
                </div>

                <conference-platform-logo
                    :platform="product.host.defaultVideoConferencePlatform"
                    class="max-w-none at600:max-w-[200px]"
                ></conference-platform-logo>
            </div>
            <p
                v-else
                class="max-w-[440px] rounded-lg border border-gray-300 bg-white px-2 py-1.5 font-medium at1024:px-6 at1024:py-4"
            >
                After you book you will receive an email with a meeting link
                that you can share with your team
            </p>

            <div
                class="space-y-3"
                v-if="
                    product.host?.canUseClientVideoLink &&
                    product.host?.supportedVideoConferencePlatforms
                "
            >
                <p class="text-sm font-semibold">
                    Prefer to use your own platform? This host supports the
                    following options:
                </p>
                <ul class="grid grid-cols-2 gap-4 at600:grid-cols-3">
                    <li
                        v-for="(label, platform) in product.host
                            .supportedVideoConferencePlatforms"
                        class="flex items-center space-x-3"
                    >
                        <conference-platform-logo
                            :platform="platform"
                            :img-alt="label"
                            class="w-full"
                        ></conference-platform-logo>
                    </li>
                </ul>
            </div>

            <div
                v-else
                class="flex flex-row items-center space-x-2 rounded bg-gray-200 p-4"
            >
                <exclamation-triangle-icon
                    class="h-6 w-6"
                ></exclamation-triangle-icon>
                <p>
                    The host is <strong>not</strong> able to use a video link
                    provided by you.
                </p>
            </div>

            <div>
                <h5 class="text-sm font-semibold">
                    Other things you will need to join:
                </h5>
                <ul class="list--checked mt-2 space-y-2">
                    <li>Link to this event, provided after purchase</li>
                    <li>
                        Internet connected device, tablets and phones are
                        supported
                    </li>
                    <li>Internet connection</li>
                </ul>
            </div>
        </div>
    </DisclosurePanel>
</template>

<script setup>
import { DisclosurePanel } from '@headlessui/vue';
import { ExclamationTriangleIcon, TvIcon } from '@heroicons-v2/vue/24/outline';
import ConferencePlatformLogo from 'components/Global/ConferencePlatformLogo';
import { inject } from 'vue';

const product = inject('product');
</script>

<style scoped></style>
