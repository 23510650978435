<template>
    <div>
        <div class="mb-6 flex flex-col gap-y-1">
            <h3 class="text-lg font-semibold">Choose a date</h3>
            <span class="text-sm font-normal text-gray-400"
                >Optionally filter for the exact date of your event.</span
            >
        </div>

        <div class="relative flex flex-col gap-y-6 text-sm">
            <date-picker
                :autoApply="true"
                :enableTimePicker="false"
                :min-date="minDate"
                :max-date="maxDate"
                :month-change-on-scroll="false"
                placeholder="Select a date"
                :transitions="false"
                v-model="selectedDate"
                class="max-w-[330px]"
                week-start="0"
            >
                <template #input-icon>
                    <calendar-icon
                        class="ml-3 h-5 w-5 stroke-1 text-black"
                    ></calendar-icon>
                </template>
            </date-picker>
        </div>
    </div>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import { useEventDateFilter } from '@/composables/useEventDateFilter';
import { CalendarIcon } from '@heroicons/vue/outline';
import DatePicker from '@vuepic/vue-datepicker';
import { DateTime } from 'luxon';
import { watch } from 'vue';

const props = defineProps({
    current: {
        required: false,
        default: () => null,
    },
});

const { queueFilter } = useCollectionFilters();
const { minDate, maxDate, selectedDate, dateFormat } = useEventDateFilter(
    props.current
);

watch(selectedDate, (newValue) => {
    let selectedDateFormatted = null;
    if (newValue) {
        selectedDateFormatted =
            DateTime.fromJSDate(newValue).toFormat(dateFormat);
    }
    queueFilter('event_date', selectedDateFormatted);
});
</script>
