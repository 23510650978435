import { DateTime } from 'luxon';
import { ref } from 'vue';

const minDate = DateTime.now().plus({ days: 1 }).toJSDate();
const maxDate = DateTime.now().plus({ days: 120 }).toJSDate();

export function useEventDateFilter(
    initialDate = null,
    dateFormat = 'yyyy-L-dd z'
) {
    if (initialDate) {
        initialDate = DateTime.fromFormat(initialDate, dateFormat);
        if (initialDate.isValid) {
            initialDate = initialDate.toJSDate();
        } else {
            initialDate = null;
        }
    }
    const selectedDate = ref(initialDate);

    return {
        minDate,
        maxDate,
        selectedDate,
        dateFormat,
    };
}
