<template>
    <div class="relative flex items-end justify-between pb-6">
        <div>
            <slot name="title.before"></slot>
            <slot name="title">
                <h1 v-if="title" class="text-xl font-medium at768:text-2xl">
                    {{ title }}
                </h1>
            </slot>
            <slot name="title.after"></slot>
            <p v-if="totalResults !== false" class="h-4 text-sm text-gray">
                <slot name="results.copy">
                    <span>
                        {{ totalResults }} results based on your filters</span
                    >
                </slot>
            </p>
        </div>
        <slot name="header.right"></slot>
    </div>
</template>

<script>
export default {
    name: 'GridHeader',
    props: {
        title: {
            type: String,
            required: false,
            default: '',
        },
        totalResults: {
            type: [Number, Boolean],
            required: false,
            default: false,
        },
    },
};
</script>
